<template>
  <div
    v-if="!loading && trackingImage"
  >
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <div
        class="d-block img-fluid w-100 banner-img position-relative shadow-lg"
        :style="{'backgroundImage': 'url(' + trackingImage +') '}"
        alt="image slot"
      >
        <div
          class="w-100 banner-img"
          style="position:absolute;top:0;left:0;background-image:linear-gradient(180deg, rgba(172,172,172,0) 0%, rgba(0,0,0,0.8) 100%, rgba(0,0,0,1) 100%);"
        />
      </div>
    </b-overlay>
    <div
      class="d-flex justify-content-center align-items-center"
      style="position:absolute; top:0; left:0; width:100vw; height:100vh;"
    >
      <b-col
        cols="11"
        md="8"
        xl="5"
        class=""
      >
        <div
          class="py-5 px-2 px-md-5 rounded-lg"
          style="background-color:rgba(0,0,0,0.6);backdrop-filter: blur(5px);"
        >
          <b-form @submit.prevent="onSearch">
            <b-form-input
              v-model="waybill"
              class="text-dark shadow pl-2 font-weight-semibold"
              style="font-size:1.5rem; height:5.1rem;"
              placeholder="Enter Waybill Number"
              required
            />
            <b-button
              variant="primary"
              class="w-100 mt-2"
              type="submit"
              style="height:4rem;font-size:1.5rem;"
            >Search</b-button>
          </b-form>
        </div>
      </b-col>
    </div>
    <div id="timeline">
      <div
        v-if="order"
        class="timeline mt-5"
      >
        <div class="font-weight-bolder font-large-2  mb-1">
          {{ order.waybill_number }}
        </div>
        <div class="font-weight-bold font-small-5 mb-1">
          COD : {{ order.cod }} &nbsp;|&nbsp; Weight : {{ order.weight }}
        </div>
        <div class="font-weight-bold font-small-5  mb-1">
          Merchant : {{ order.merchant }}
        </div>
        <div class="font-weight-bold font-small-5  mb-1">
          Customer : {{ order.customer_name }}
        </div>
      </div>
      <div
        v-if="timelineData.length>0"
        class="w-100 d-flex justify-content-center align-items-center"
      >
        <div class="w-100 timeline">
          <app-timeline class="mt-3 mb-5">
            <app-timeline-item
              v-for="(item, index) in timelineData"
              :key="index"
              :variant="`${item.status.color || 'primary'}`"
              :icon="item.status.icon"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between align-items-start align-items-md-center mb-1 mb-sm-0">
                <h3 style="margin-top:0.3rem; font-size:1.4rem;">
                  {{ item.status.name }}
                </h3>
                <small
                  class="text-muted"
                  style="font-size:1.2rem;"
                >{{ __dateTimeFormatter(item.date_time) }} | {{ item.date_time_ago }}</small>
              </div>
            </app-timeline-item>
          </app-timeline>

        </div>
      </div>
    </div>
    <div
      v-if="timelineData.length === 0 && !loading"
      class="d-flex justify-content-center align-items-center my-5"
    >
      <div class="font-large-1 font-weight-bold text-center mx-2">
        Please enter a waybill number to search
      </div>
    </div>
  </div>
</template>

<script>
import {
  BOverlay,
  BFormInput,
  BButton,
  BForm,
  BCol,
} from 'bootstrap-vue'
import ErrorMessage from '@core/mixins/ErrorMessage'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import { bucketUrl } from '@/constants/config'

const trackingRepository = RepositoryFactory.get('tracking')

export default {
  components: {
    BOverlay,
    BCol,
    BFormInput,
    BButton,
    BForm,
    AppTimeline,
    AppTimelineItem,
  },
  mixins: [ErrorMessage],
  data() {
    return {
      order: null,
      timelineData: [],
      loading: true,
      waybill: null,
      trackingImage: '/1.jpg',
    }
  },
  mounted() {
    this.getOrderTrackingImage()
  },
  methods: {
    async getOrderTrackingInfo(payload) {
      try {
        this.loading = true
        const { data } = await trackingRepository.getOrderTrackingInfo(payload)
        this.timelineData = data.data.timeline.reverse()
        this.order = data.data.order
        this.loading = false
      } catch (e) {
        this.timelineData = []
        this.loading = false
        this.convertAndNotifyError(e)
      }
    },
    async onSearch() {
      const payload = {
        waybill_number: this.waybill,
      }
      await this.getOrderTrackingInfo(payload)
      this.scrollToBottom()
    },
    scrollToBottom() {
      document.getElementById('timeline').scrollIntoView(
        {
          behavior: 'smooth',
        },
      )
    },
    async getOrderTrackingImage() {
      try {
        this.loading = true
        const { data } = await trackingRepository.getTrackingPublicSettingsResource()
        const isSet = data.data.tracking_page_image
        this.trackingImage = isSet ? `${bucketUrl}/${data.data.tracking_page_image}` : this.trackingImage
        this.loading = false
      } catch (e) {
        this.loading = false
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>
<style scoped>

.banner-img {
  height: 100vh !important;
  object-fit: cover;
  border-radius: 0px 0px 10xp 10px;;
  transition: all 0.3s ease;
  filter: brightness(1.3);
  background-size: cover;
  background-position: center;
}
.banner-img:hover {
  filter: brightness(1.4);
}

.timeline {
  padding-left: 20%;
  padding-right: 20%;
}
@media (max-width: 991px) {
  .banner-img {
    height: 60vh;
  }

  .timeline {
  padding-left:5%;
  padding-right: 5%;
}
}
</style>
